import { ExamCellActions } from "./action";
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const ExamCellSagas = function* () {
     yield all([
       takeEvery(ExamCellActions.GET_EXAMCELLACTIONS, getExamCell),
       takeEvery(ExamCellActions.GET_LIBRARY_ACTIONS, getLibrary),
       takeEvery(ExamCellActions.GET_LABS_ACTIONS, getLabs),

     ]);
   };
   
   const getExamCell = function* (data) {
     try {
       const result = yield call(() => axios.get(`${API_URL}/examcell`));
   
       yield put({
         type: ExamCellActions.SET_EXAMCELLACTIONS,
         payload: result.data,
       });
     } catch (error) {
       console.error("Error", error);
     }
   };

   const getLibrary = function* (data) {
     try {
       const result = yield call(() => axios.get(`${API_URL}/library`));
   
       yield put({
         type: ExamCellActions.SET_LIBRARY_ACTIONS,
         payload: result.data,
       });
     } catch (error) {
       console.error("Error", error);
     }
   };

   const getLabs = function* (data) {
     try {
       const result = yield call(() => axios.get(`${API_URL}/labs`));
   
       yield put({
         type: ExamCellActions.SET_LABS_ACTIONS,
         payload: result.data,
       });
     } catch (error) {
       console.error("Error", error);
     }
   };



