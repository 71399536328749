import { ExamCellActions } from "./action";

const initialState = {
  ExamCelldata: [],
  LibraryData: [],
  LabsData: [],
};

export const ExamCellReducer = (state = initialState, action) => {
  switch (action.type) {
    case ExamCellActions.SET_EXAMCELLACTIONS:
      return {
        ...state,
        ExamCelldata: action.payload,
      };

    case ExamCellActions.SET_LIBRARY_ACTIONS:
      return {
        ...state,
        LibraryData: action.payload,
      };

    case ExamCellActions.SET_LABS_ACTIONS:
      return {
        ...state,
        LabsData: action.payload,
      };

    default:
      return state;
  }
};
