import React, { useEffect } from 'react';
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import Curricular from "../components/Submenu/Curricular/Curricular"

function CurricularIndex() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header/>
  <Curricular/>
      <Footer/>
    </div>
  )
}

export default CurricularIndex