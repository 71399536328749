import React, { useRef, useState, useEffect, useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";
import Particle from "../../common/Particles";

const Loader = () => (
  <div className="loader-container-testimonial">
    <div className="loader-testimonial"></div>
  </div>
);

// Debounce function
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const MemoizedParticle = React.memo(Particle);

// Testimonials Component
const Testimonials = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const sliderRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [animateText, setAnimateText] = useState(false);

  const { Testimonial } = useSelector((state) => state.HomePageReducer);

  // Fetch testimonials on component mount
  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_TESTIMONIAL });
  }, [dispatch]);

 
  useEffect(() => {
    if (Testimonial && Testimonial.length > 0) {
      setLoading(false);
    }
  }, [Testimonial]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollTop = window.pageYOffset;
      const section = document.querySelector(".testimonials");

      if (section) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        const isInSection =
          scrollTop > sectionTop - window.innerHeight + sectionHeight / 2;

        if (isInSection && !animateText) {
          setAnimateText(true);
          window.removeEventListener("scroll", handleScroll);
        }
      }
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [animateText]);

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const openPopup = (url) => {
    setVideoUrl(url);
    setShowPopup(true);
  };

  const closePopup = () => {
    setVideoUrl("");
    setShowPopup(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    lazyLoad: "ondemand",
  };

  const memoizedSlider = useMemo(() => {
    return (
      <Slider {...settings} ref={sliderRef}>
        {Testimonial.map((item, index) => (
          <div className="item " key={index}>
            <div className="content">
              <div className="row align-items-center">
                <div
                  className={`col-lg-6 left-clumn ${
                    animateText ? "animated" : ""
                  }`}
                >
                  <div className="caption d-slideup wow home-gap-testimonial-text">
                    <h2 className="d-slideup wow">
                      <span className="tes-big-text">
                        <span className="up-text testimonial-text-heading home-gap-testimonial-text">
                          {item.title}
                        </span>
                      </span>
                    </h2>
                    <p className="testimonial-text">{item.content}</p>

                    {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => {
                    const point = item[`point${num}`];
                    return point ? (
                      <p key={num} className="testimonial-point">{point}</p>
                    ) : null;
                  })}
                  
                  </div>
                </div>
                <div
                  className="col-lg-6 right-clumn"
                  onClick={() =>
                    item.file.endsWith(".mp4") && openPopup(item.file)
                  }
                >
                  <div className="img-vid home-gap-testimonial-img">
                    {item.thumbnailFile ? (
                      <img
                        src={
                          item.thumbnailFile ? item.thumbnailFile : item.file
                        }
                        alt=""
                        className="testimonial-image"
                        loading="lazy"
                      />
                    ) : (
                      <img
                        src={item.file}
                        alt=""
                        className="testimonial-image"
                      />
                    )}
                    {item.file.endsWith(".mp4") && (
                      <div className="curv-butn">
                        <div className="icon">
                          <i className="fas fa-play"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }, [Testimonial, animateText]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="testimonial-global">
      <section className="testimonials section-padding">
        <div id="particles-js">
          <MemoizedParticle />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 position-re view-tog-wrap">
              {memoizedSlider}

              <div className="swiper-arrow-control control-abslout">
                <div className="swiper-button-prev" onClick={handlePrevSlide}>
                  <span className="ti-arrow-circle-left testimonial-btn"></span>
                </div>
                <div className="swiper-button-next" onClick={handleNextSlide}>
                  <span className="ti-arrow-circle-right testimonial-btn"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Close Button */}
        {showPopup && (
          <div className="close-btn-testimonial" onClick={closePopup}>
            <i className="fas fa-times"></i>
          </div>
        )}
        {/* Video Popup */}
        {showPopup && (
          <div className="video-popup">
            <div className="video-container-testimonial">
              <video controls autoPlay className="video-content">
                <source src={videoUrl} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Testimonials;
