export const routes = {
  "Psg Iaq": "/psg-iaq",
  "The Trust": "/the-trust",
  "PSG & Sons’ Charities": "https://www.psgsonscharities.org",
  Administration: "/administration",
  "Statutory Committee": "/statutory-committee",
  "Facts & Figures": "/facts-and-figures",
  Principal: "/principal",
  "Core Faculty": "/core-faculty",
  "Visiting Faculty": "/visiting-faculty",
  "Allied Faculty": "/allied-faculty",
  "Administrative Staffs": "/administrative-staffs",
  "Expert Panel Memebers": "/expert-panel-members",
  "Design Chair": "/design-chair",
  Programs: "/programs",
  "Academic Timetable": "/academic-time-table",
  "Academic Calendar": "/academic-calendar",
  Syllabus: "/syllabus",
  Admissions: "/admissions",
  // "Life At Campus": "/life-at-campus",
  "Monthly lecture series": "/monthly-lecture-series",
  "Study Tour": "/study-tour",
  "Site Visits & Field Visits": "/site-visits&field-visits",
  NASA: "/nasa",
  Symposium: "/symposium",
  Editorial: "/editorial",
  "Connect with us": "/ConnectWithus",
  IQAC: "/iqac",
  Admissions: "/admissions",
  "Work with us": "/work-with-us",
  Archigazette: "/",
  ListPageNewsandEvents: "/newsandevents",
  Curricular: "/curricular",
  "Co-Curricular": "/co-curricular",
  "Extra Curricular": "/extra-curricular",
  Collaboration: "/collaboration",
"Exam Cell":"/exam-cell",
"Library":"/library",
"Labs":"/labs"

};
