import { all, put, takeEvery, call } from "redux-saga/effects"
import { CocurricularActions } from "./action"
import { API_URL } from "../../utils/Constant"
import axios from "axios"

export const CocurricularSagas = function* () {
    yield all([
        takeEvery(CocurricularActions.GET_COCURRICULAR,
            getCocurricular
        )
    ])
}
const getCocurricular= function* (data) {
    try{
        const result = yield call (()=> axios.get (`${API_URL}/co-curricular`))
        yield put({
            type:CocurricularActions.SET_COCURRICULAR,
            payload: result.data
        })
    }
    catch (error) {
        console.error("Error", error);
      }
}