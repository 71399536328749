import React, { useEffect, useState } from "react";
import Logo from "../../assets/imgs/logo.webp";
import Hmenu from "../../assets/imgs/h-menu.webp";
import $ from "jquery";
import SideNavigation from "../Menu-sidenavbar/SideNavigation ";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MarqueeText from "../../common/marquee";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Search from "../../common/Search";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes";
import {
  faAddressBook,
  faSuitcase,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
// const { SubMenu } = Menu;

const Header = ({ Color, isInGallery, isInProject, charityLink }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [isSocialOpen, setIsSocialOpen] = useState(false);

  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    $(".navbar .search-form").on("click", ".search-icon", function () {
      $(".navbar .search-form").toggleClass("open");

      if ($(".navbar .search-form").hasClass("open")) {
        $(".search-form .close-search").slideDown();
      } else {
        $(".search-form .close-search").slideUp();
      }
    });

    $(".navbar").on("click", ".navbar-toggler", function () {
      $(".navbar .navbar-collapse").toggleClass("show");
    });

    $(window).on("scroll", function () {
      var bodyScroll = window.scrollY,
        navbar = $(".navbar"),
        logo = $(".navbar.change .logo > img");

      if (bodyScroll > 300) {
        navbar.addClass("nav-scroll");
        logo.attr("src", "assets/imgs/logo-dark.png");
      } else {
        navbar.removeClass("nav-scroll");
        logo.attr("src", "assets/imgs/logo-light.png");
      }
    });

    // Clean up jQuery events
    return () => {
      $(".navbar .search-form").off("click", ".search-icon");
      $(".navbar").off("click", ".navbar-toggler");
      $(window).off("scroll");
    };
  }, []);

  const handleMenuItemClick = (event) => {
    const submenu = event.target.nextSibling;
    if (submenu && submenu.classList.contains("sub-menu")) {
      submenu.classList.toggle("show");
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

  const { Footerdata } = useSelector((state) => state.FooterReducer);

  const handleSocialToggle = () => {
    setIsSocialOpen(!isSocialOpen);
  };

  return (
    <>
      <nav
        className={`navbar ${isScrolled ? "nav-scroll" : ""} ${
          isInGallery ? "gallery-navbar" : ""
        }`}
      >
        {/* {/ Top section /} */}
        <div className="top-men">
          <div className="container-fluid">
            <div className="row align-items-center">
              <MarqueeText />
            </div>
          </div>
        </div>
{/* 
        {/ Logo, Search Form, and Menu /} */}
        <div className="container h-top logo-socialicon">
          {/* {/ Logo /} */}
          <a className="logo " href="/">
            {isInGallery ? (
              <img src={Hmenu} alt="logo" className="res-logo1" />
            ) : (
              <img
                src={isScrolled ? Hmenu : Logo}
                alt="logo"
                className="res-logo"
              />
            )}
          </a>

          {/* {/ Search Form /} */}
          {/* <div className="search-form ml-auto">
            <div className="form-group">
              <input type="text" name="search" placeholder="Search" />
              <button>
                <span className="pe-7s-search"></span>
              </button>
            </div>
            <div className="search-icon" style={{ color: Color }}>
              <span className="pe-7s-search open-search"></span>
              <span className="pe-7s-close close-search"></span>
            </div>
          </div> */}

          {/*           
          <Search
            routes={Object.keys(routes).map((name) => ({
              name,
              path: routes[name],
            }))}
            navigate={navigate}
            handleMenuToggle={handleMenuToggle}
          /> */}

          {/* {/ Menu /} */}
          <div className="topnav d-flex">
            <li className="hover-this cursor-pointer  header-icons h-work-icon">
              <Link
                to="/work-with-us"
                style={{
                  color: isScrolled || isInGallery ? "black" : "",
                }}
                className={isScrolled || isInGallery ? "" : "white"}
              >
                <motion.div whileHover={{ scale: 1.6 }}>
                  <FontAwesomeIcon icon={faSuitcase} />
                </motion.div>
              </Link>
            </li>

            <li className="hover-this cursor-pointer header-icons h-contact-icon ">
              <Link
                to="/ConnectWithus"
                style={{
                  color: isScrolled || isInGallery ? "black" : "",
                }}
                className={isScrolled || isInGallery ? "" : "white"}
              >
                <motion.div whileHover={{ scale: 1.6 }}>
                  <FontAwesomeIcon icon={faAddressBook} />
                </motion.div>
              </Link>
            </li>

            <div
              className="menu-icon cursor-pointer res-menu1"
              style={{ color: Color }}
              onClick={handleMenuToggle}
            >
              <span className="icon ti-menu" style={{ color: Color }}></span>
              Menu
            </div>

            <div className="social-container" style={{ position: "relative" }}>
  <div onClick={handleSocialToggle} className="cursor-pointer social-toggle-icon">
    <FontAwesomeIcon
      icon={faGlobe}
      style={{
        color: isScrolled || isInGallery ? "black" : "",
      }}
      className={isScrolled || isInGallery ? "" : "white"}
    />
  </div>
  {isSocialOpen && (
    <ul
      className="social-icons"
      style={{
        position: "absolute",
        top: "100%", // Positions below the globe icon
        left: "5px",   // Aligns to the left of the globe icon
        marginTop: "8px", // Optional spacing
      }}
    >
      {Footerdata?.Social_links?.map((social, index) => (
        <React.Fragment key={index}>
          {social.facebook && (
            <li className="hover-this cursor-pointer">
              <a
                href={social.facebook}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: isScrolled || isInGallery ? "black" : "",
                }}
                className={isScrolled || isInGallery ? "" : "white"}
              >
                <motion.i
                  className="fab fa-facebook-f"
                  whileHover={{ scale: 1.6 }}
                />
              </a>
            </li>
          )}
          {social.linkedin && (
            <li className="hover-this cursor-pointer">
              <a
                href={social.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: isScrolled || isInGallery ? "black" : "",
                }}
                className={isScrolled || isInGallery ? "" : "white"}
              >
                <motion.i
                  className="fab fa-linkedin-in"
                  whileHover={{ scale: 1.6 }}
                />
              </a>
            </li>
          )}
          {social.instagram && (
            <li className="hover-this cursor-pointer">
              <a
                href={social.instagram}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: isScrolled || isInGallery ? "black" : "",
                }}
                className={isScrolled || isInGallery ? "" : "white"}
              >
                <motion.i
                  className="fab fa-instagram"
                  whileHover={{ scale: 1.6 }}
                />
              </a>
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  )}
</div>
          </div>
        </div>
      </nav>

      <SideNavigation
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
    </>
  );
};

export default Header;
