import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";

function AboutPrincipal() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_ABOUTPRINCIPAL });
  }, [dispatch]);

  const { AboutPrincipal } = useSelector((state) => state.HomePageReducer);
  const [animateText, setAnimateText] = useState(false);
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const section = document.querySelector(".about");

      if (section) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        const isInSection =
          scrollTop > sectionTop - window.innerHeight + sectionHeight / 2;

        if (isInSection && !animationTriggered) {
          setAnimateText(true);
          setAnimationTriggered(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [animationTriggered]);

  const openPopup = (url) => {
    setVideoUrl(url);
    setShowPopup(true);
  };

  const closePopup = () => {
    setVideoUrl("");
    setShowPopup(false);
  };

  const truncateTextAtFullStop = (text, limit) => {
    if (text.length <= limit) return text;

    const truncated = text.substring(0, limit);
    const lastPeriodIndex = truncated.lastIndexOf(".");

    return lastPeriodIndex !== -1
      ? text.substring(0, lastPeriodIndex + 1)
      : truncated;
  };

  return (
    <section className="about sub-bg home-gap-team" data-scroll-index="2">
      <div className="container section-padding">
        {AboutPrincipal.map((item, index) => (
          <div className="row md-marg manage-wrap" key={index}>
            <div className="col-lg-6">
              <div className={`cont md-mb50 ${animateText ? "animate" : ""}`}>
                <h6 className="sub-title main-color mb-15">
                 Team
                </h6>
                <h2 className="fw-600 d-rotate wow">
                  <span className="rotate-text principal-name fw-600 princi">
                    {item.name}
                  </span>
                </h2>
                <div className="d-flex align-items-end mt-50">
                  <div>
                    <p style={{textAlign:"justify"}}>{truncateTextAtFullStop(item.description, 600)}</p>

                    <div className="mt-30">
                      <Link to="/principal" className="go-more">
                        <span className="text">Read More </span>
                        <span className="icon ti-arrow-circle-right principal-text "></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {/* <div className="img-vid" onClick={() => openPopup(PSGvideo)}> */}
              <img src={item.file} alt="" />
              {/* <div className="curv-butn principal-btn">
             <div className="icon">
               <i className="fas fa-play"></i>
             </div>
           </div> */}
              {/* </div> */}
            </div>
          </div>
        ))}
      </div>
      {/* Close Button */}
      {showPopup && (
        <div className="close-btn-testimonial" onClick={closePopup}>
          <i className="fas fa-times"></i>
        </div>
      )}
      {/* Video Popup */}
      {showPopup && (
        <div className="video-popup">
          <div className="video-container-testimonial">
            <video controls autoPlay className="video-content">
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </section>
  );
}

export default AboutPrincipal;
