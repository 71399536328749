import React, { useEffect, useState } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Container, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const Gallery = ({ title, description, images }) => {
  const { DetailPage } = useSelector((state) => state.HomePageReducer);
  console.log("DetailPage", DetailPage);

  useEffect(() => {
    Fancybox.bind('[data-fancybox="gallery"]');
  }, [images]);

  return (
    <Container>
      <section className="main-grid">
        <div className="gallery-head">
          <h1 className="text-center h1 pb-40 gallery-heading">{title}</h1>
          <p className="mb-30 ml-20 mr-15">{description}</p>
        </div>

        <div className="container">
          {images.length === 0 ? (
            <div className="loader-container">
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            images.map((image, index) => (
              <div className="card" key={index}>
                <div className="card-image">
                  <a
                    href={image.image}
                    data-fancybox="gallery"
                    data-caption={image.content || "Image Gallery"}
                  >
                    <img src={image.image} alt="Gallery" />
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </Container>
  );
};

export default Gallery;
