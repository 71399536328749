import React, { useState } from "react";
import HeaderBackground from "../../../common/SubpagesBGimage";
import submenuservicon from "../../../assets/imgs/serv-icons/1.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
function Editorial() {
  const [activeTab, setActiveTab] = useState("tabs-1");

 

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const routeNames = {
    "/editorial": {
      menuName: "Learning Events",
      subMenuName: "Editorial"
,
    },
  };
  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  

  const sections = [
    {
      section: { section_id: 1, section_name: " Archigazette" },
      contents: [
        { section_content: "To facilitate active learning and vocational training." },
        { section_content: "To encourage and promote questioning spirit and 'can-do' entrepreneurial attitude." },
        { section_content: "To foster industry - institute collaboration." },
        { section_content: "To ignite passion for creative work and selfless service towards a sustainable world." },
        { section_content: "To provide intellectually stimulating environment, conducive for research." },
        { section_content: "To establish a State-of-the-art comprehensive design institute." }
      ]
    },
    {
      section: { section_id: 2, section_name: "Objective & Strategies" },
      contents: [
        { section_content: "To achieve excellence in Architectural education, and nurture architects with ingenuity, intelligence, and integrity to face global challenges to serve industry and society." }
      ]
    },
    {
      section: { section_id: 3, section_name: "Functions and Benefits" },
      contents: [
        { section_content: "Components of Architecture Learning" },
        { section_content: "To achieve excellence in Architectural education, and nurture architects with ingenuity, intelligence, and integrity to face global challenges to serve industry and society." }
      ]
    }
  ];


  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding mb-80 abt-uss">
        <div className="container">
          <div className="cal-action pb-10 mb-40 bord-thin-bottom about-us-texts">
            <h6 className="sub-title mb-15 main-color ">Editorial</h6>
          </div>
          <div className="sec-head mb-30"></div>
          <div className="text mb-50">
            <p className="para-psg">
              Architecture is more than just building design; it's about
              creating spaces with sense, soul, sanctity, life, and character
              and an architect evolves them by skilfully integrating art,
              science, and engineering through an infinite ingenious process.
              With the objective of nurturing such ingenious minds, PSG IAP
              offers architectural education with unique pedagogical approaches
              which perfectly blend traditional principles with modern and
              advanced technologies.
            </p>
          </div>


          <div className="row lg-marg" id="tabs">
      {/* Mobile View */}
      <div className="d-lg-none col-12">
        <div className="serv-tab-link tab-links full-width pt-40">
          <div className="row justify-content-end">
            <div className="col-lg-12">
              <ul className="rest">
                {sections.map((section, index) => (
                  <li
                    key={section.section.section_id}
                    className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${activeTab === `tabs-${index + 1}` ? "current" : ""}`}
                    onClick={() => handleTabClick(`tabs-${index + 1}`)}
                  >
                    <h5>
                      <span className="fz-18 opacity-7 mr-15">{`0${index + 1}`}</span>
                      {section.section.section_name}
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className="col-lg-4 d-none d-lg-block side-txt">
        <div className="serv-tab-link tab-links full-width pt-40">
          <div className="row justify-content-end">
            <div className="col-lg-12 iaq-padding">
              <ul className="rest">
                {sections.map((section, index) => (
                  <li
                    key={section.section.section_id}
                    className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${activeTab === `tabs-${index + 1}` ? "current" : ""}`}
                    onClick={() => handleTabClick(`tabs-${index + 1}`)}
                  >
                    <div className="u-line linee psg-iaq-tabs">
                      <span className="icon ti-arrow-circle-right tabss-psg "></span>
                      <span className="text fz-20 psg-margin">
                        {section.section.section_name}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Tab Content */}
      <div className="col-lg-8 col-12">
        <div className="serv-tab-cont md-mb80 sticky-item">
          {sections.map((section, index) => (
            <div
              key={section.section.section_id}
              className={`tab-content ${activeTab === `tabs-${index + 1}` ? "current" : ""}`}
              id={`tabs-${index + 1}`}
            >
              <div className="item">
                <div className="sub-bg">
                  <div className="icon-img-60 mb-40">
                    <img src={submenuservicon} alt="" />
                  </div>
                  <div className="text">
                    <ul className="rest list-arrow mt-30 pt-30 bord-thin-top">
                      {section.contents.map((content, contentIndex) => (
                        <li key={contentIndex} className="pointtxt">
                          {section.contents.length > 1 && (
                            <span className="icon psgiap-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="2.2em" height="2.2em" viewBox="0 0 24 24">
                                <circle cx="12.1" cy="12.1" r="1" fill="none" stroke="#84e3c6" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                              </svg>
                            </span>
                          )}
                          <p className="inline fw-400 fz-16 text ml-5 mt-2">
                            {content.section_content}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

      
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Editorial;
