import React, { useState } from "react";
import graduation from "../../../assets/imgs/Curricular.jpg";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import HeaderBackground from "../../../common/SubpagesBGimage";

function Collaboration() {
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState("Curricular");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  const routeNames = {
    "/life-at-campus": {
      menuName: "Life At Campus",
      subMenuName: "Collaboration",
  }
};

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
                                  
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  // Hardcoded data
  const LifeAtCampusData = [
    {
      category: "Curricular",
      image: graduation,
      description: "Experience a variety of engaging curricular activities.",
    },
    {
      category: "Extra-Curricular",
      image: graduation,
      description: "Explore opportunities beyond the classroom.",
    },
    {
      category: "Sports",
      image: graduation,
      description: "Stay active and healthy with our sports programs.",
    },
    {
      category: "Cultural",
      image: graduation,
      description: "Celebrate diversity through cultural events.",
    },
  ];

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  return (
    <div>
      <main className="main-bg blog-wrap life-at-campus">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <section className="work-stand section-padding sub-bg ">
          <div className="container-xxl">
            <div className="row">
              <div className="filtering col-12 text-center life-at-campus-filtering">
                <div className="filter">
                  {LifeAtCampusData.map((item, index) => (
                    <span
                      key={index}
                      className={
                        item.category === activeCategory ? "active" : ""
                      }
                      onClick={() => handleCategoryChange(item.category)}
                    >
                      {item.category}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="gallery row stand-marg student-project-wrap">
              {LifeAtCampusData.map((item, index) => (
                <div
                  key={index}
                  className={`col-lg-3 col-md-6 items cat-${index + 1} ${
                    item.category === activeCategory ? "active" : ""
                  }`}
                >
                  <div className="item mt-40 life-at-campus-contents">
                    <div className="img">
                      <a href="student-details.html" className="pro-img">
                        <img
                          src={item.image}
                          alt={item.category}
                          className="life-at-campus-image"
                        />
                      </a>
                    </div>
                    <div className="cont mt-20">
                      <h5 className="fz-22">
                        <a href="student-details.html" className="pro-img">
                          {item.category}
                        </a>
                      </h5>
                      <p>
                        <a href="student-details.html" className="pro-img">
                          {item.description}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Collaboration;
