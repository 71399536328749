import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import StudentProjects from "../components/Student Portfolio/Student-Detailpage/index";
import Loading from "../common/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { HomePageActions } from "../Store/Home/action";

function StudentProjectListPage() {
  document.title = "StudentProjects";
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
    // const backgroundColor = "black";
    const textColor = "black";

    const { StudentPortfolioDetailpage } = useSelector(
      (state) => state.HomePageReducer
    );
  
    const [loading, setLoading] = useState(true);
    const [projectname, setProjectname] = useState("");
    const [description, setDescription] = useState("");
    const [images, setImages] = useState([]);
    
    useEffect(() => {
      window.scrollTo(0, 0);
  
      if (state?.projectId) {
        dispatch({
          type: HomePageActions.STUDENT_PORTFOLIO_DETAILPAGE,
          payload: {
            projectId: state.projectId,
          },
        });
      }
    }, [dispatch, state]);
  
    useEffect(() => {
      if (StudentPortfolioDetailpage) {
        setLoading(false);
        setProjectname(StudentPortfolioDetailpage.projectname || "");
        setDescription(StudentPortfolioDetailpage.description || "");
        setImages(StudentPortfolioDetailpage.images || []);
      } else {
        setLoading(true);
        setProjectname("");
        setDescription("");
        setImages([]);
      }
    }, [StudentPortfolioDetailpage]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header Color={textColor} isInGallery={true} />
          {/* <Header backgroundColor={backgroundColor} /> */}
          <StudentProjects
            projectname={projectname}
            description={description}
            images={images}
          />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default StudentProjectListPage;