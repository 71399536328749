import React, { useEffect } from 'react';
import Editorial from '../components/Submenu/Editorial/Editorial'
import Loading from '../common/Loading'
import { PageOpeningAnimation } from '../common/pageOpeningAnimation'
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
function EditorialIndex() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

    <>
    {/* <PageOpeningAnimation/> */}
    <Header/>
    <Editorial/>
    <Footer/>
    </>
 
    </div>
  )
}

export default EditorialIndex